import React, { useState, useEffect } from "react"
import LayoutTwo from "../components/LayoutTwo"
import Hero from "../components/Hero"
import Services from "../components/Services"
import ServicesEn from "../components/ServicesEn"
import SEO from "../components/SEO"
import Loader from "../components/Loader"
import { useLanguage } from "../contexts/LanguageContext"

export default () => {
  const [showLoader, setShowLoader] = useState(true)

  useEffect(() => {
    // Check if the site has been visited during the current session
    const hasVisited = sessionStorage.getItem("hasVisited")

    if (!hasVisited) {
      // Show the loader if not visited and set a flag
      sessionStorage.setItem("hasVisited", "true")
      setTimeout(() => {
        setShowLoader(false)
      }, 3000)
    } else {
      // Hide the loader immediately if already visited
      setShowLoader(false)
    }
  }, [])

  const { selectedLanguage } = useLanguage() // Use the useLanguage hook

  if (showLoader) {
    return <Loader />
  }

  return (
    <LayoutTwo>
      <SEO title="Osijek Koteks d.d." />
      <Hero />
      {selectedLanguage === "EN" ? <ServicesEn /> : <Services />}
    </LayoutTwo>
  )
}
